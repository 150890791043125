const { createTheme } = require('@mui/material')

const theme = createTheme({
	palette: {
		background: {
			default: '#2d2e41',
		},
		primary: {
			main: '#00c853',
			light: '#5efc82',
			dark: '#009624',
		},
		secondary: {
			main: '#2d2e41',
			light: '#56576c',
			dark: '#04031b',
		},
		text: {
			primary: '#000000',
			secondary: '#ffffff',
		},
	},
})

export default theme
