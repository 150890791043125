import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material'

const ConfirmationDialog = ({ handleClose, data }) => {
	return (
		<Dialog
			open={data.open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle>{data.title}</DialogTitle>
			<DialogContent>
				<DialogContentText color={'secondary'}>
					{data.message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Disagree</Button>
				<Button onClick={handleClose} autoFocus>
					Agree
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationDialog
