import { Box } from '@mui/material'

const TabPanel = ({ children, value, index, sx, ...other }) => {
	return (
		<Box
			sx={sx}
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</Box>
	)
}

export default TabPanel
