import { Box, Typography } from '@mui/material'

const MarketplacePage = () => {
	return (
		<Box>
			<Typography>Marketplace page</Typography>
		</Box>
	)
}

export default MarketplacePage
