import { useTheme } from '@emotion/react'
import { Button, Divider, LinearProgress } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import printerService from '../../services/printerService'

const style = theme => ({
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	maxHeight: 500,
	bgcolor: theme.palette.secondary.main,
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	[theme.breakpoints.down('sm')]: {
		width: '95vw',
	},
})

const ErrorModal = () => {
	const { isError } = useSelector(state => state.gameData)
	const [isOpen, setIsOpen] = useState(isError || false)
	const theme = useTheme()

	useEffect(() => {
		setIsOpen(isError !== undefined)
	}, [isError])

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={isOpen}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={isOpen}>
				<Box sx={style(theme)}>
					<Typography color={'primary'} variant="h6">
						Error
					</Typography>
					<Divider style={{ margin: '5px 0' }} />
					<Typography
						sx={{
							overflowWrap: 'break-word',
							overflowY: 'scroll',
							backgroundColor: theme.palette.secondary.light,
							padding: '5px',
							borderRadius: '2px',
						}}
						color={'primary'}
						variant="overline"
						fontSize={10}
					>
						{isError && isError.message}
					</Typography>
					<Button
						sx={{ marginTop: '10px' }}
						variant={'contained'}
						onClick={() => setIsOpen(false)}
					>
						Ok
					</Button>
				</Box>
			</Fade>
		</Modal>
	)
}

export default ErrorModal
