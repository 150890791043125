import { useTheme } from '@emotion/react'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { providers } from 'ethers'
import web3 from 'web3'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ADDRESS_BUSD, ADDRESS_TOKEN } from '../../config'
import {
	resetGameData,
	setIsError,
	setIsLoading,
} from '../../features/gameDataSlice'
import {
	resetWeb3Provider,
	setAddress,
	setWeb3Provider,
} from '../../features/userSlice'
import { supportedChain } from '../../utils/chains'
import { ellipseAddress, getChainData, web3Modal } from '../../utils/web3Modal'
import NavigationLink from './NavigationLink'

const Navbar = () => {
	const dispatch = useDispatch()
	const { provider, address, balance } = useSelector(state => state.user)
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const verifyChainOrAsk = async chainId => {
		try {
			getChainData(chainId)
		} catch (e) {
			dispatch(
				setIsError({
					value: true,
					message: e.message,
				})
			)
			try {
				await window.ethereum.request({
					method: 'wallet_switchEthereumChain',
					params: [
						{
							chainId:
								'0x' + supportedChain.chain_id.toString(16),
						},
					],
				})
				dispatch(setIsError(undefined))
				window.location.reload()
			} catch (e) {
				try {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [
							{
								chainId:
									'0x' + supportedChain.chain_id.toString(16),
								rpcUrls: [supportedChain.rpc_url],
							},
						],
					})
					setIsError(undefined)
					window.location.reload()
				} catch (e) {}
			}
		}
	}

	const connect = async () => {
		const provider = await web3Modal.connect()
		const web3Provider = new providers.Web3Provider(provider)

		dispatch(setIsLoading(true))
		const signer = web3Provider.getSigner()
		const address = await signer.getAddress()
		const network = await web3Provider.getNetwork()
		dispatch(setIsLoading(false))

		dispatch(
			setWeb3Provider({
				provider,
				web3Provider,
				address,
				chainId: network.chainId,
			})
		)
		await verifyChainOrAsk(network.chainId)
	}

	const disconnect = async () => {
		await web3Modal.clearCachedProvider()
		if (provider?.disconnect && typeof provider.disconnect === 'function') {
			await provider.disconnect()
		}
		dispatch(resetWeb3Provider())
		dispatch(resetGameData())
	}

	useEffect(() => {
		if (web3Modal.cachedProvider && address === undefined) {
			connect()
		}
	}, [])

	useEffect(() => {
		if (provider?.on) {
			const handleAccountsChanged = accounts => {
				console.log('accountsChanged', accounts)
				dispatch(setAddress(accounts[0]))
			}

			// https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
			const handleChainChanged = _hexChainId => {
				verifyChainOrAsk(parseInt(_hexChainId))
			}

			const handleDisconnect = error => {
				console.log('disconnect', error)
				disconnect()
			}

			provider.on('accountsChanged', handleAccountsChanged)
			provider.on('chainChanged', handleChainChanged)
			provider.on('disconnect', handleDisconnect)

			// Subscription Cleanup
			return () => {
				if (provider.removeListener) {
					provider.removeListener(
						'accountsChanged',
						handleAccountsChanged
					)
					provider.removeListener('chainChanged', handleChainChanged)
					provider.removeListener('disconnect', handleDisconnect)
				}
			}
		}
	}, [provider])

	return (
		<Box sx={{ padding: '25px' }}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '100%',
					padding: '20px',
					borderBottom: `2px ${theme.palette.primary.main} solid`,
				}}
			>
				{isMobile ? (
					<Box>Mobile</Box>
				) : (
					<Box sx={{ display: 'flex' }}>
						{[
							{ name: 'Home', path: '' },
							{ name: 'Print', path: 'print' },
							{ name: 'Mint', path: 'mint' },
							{ name: 'Collection', path: 'collections' },
							{ name: 'Marketplace', path: 'marketplace' },
							{ name: 'Docs', path: 'docs' },
						].map(i => (
							<NavigationLink
								key={i.path}
								name={i.name}
								path={i.path}
							/>
						))}
					</Box>
				)}

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box mx={2}>
						<Typography color={'primary'} variant={'overline'}>
							{ellipseAddress(address, 3)} | {balance.money}{' '}
							<strong>$MNY</strong>| {balance.bnb}{' '}
							<strong>$BNB</strong>
						</Typography>
					</Box>
					{address ? (
						<Button
							onClick={disconnect}
							variant="contained"
							color="primary"
						>
							Disconnect
						</Button>
					) : (
						<Button
							onClick={connect}
							variant="contained"
							color="primary"
						>
							Connect
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default Navbar
