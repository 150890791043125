import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	LinearProgress,
	Paper,
	Slide,
	linearProgressClasses,
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ProgressBar from '@ramonak/react-progress-bar'
import { useRef, useState } from 'react'
import { tierColors, tiersEnum } from '../../data/tiers'
import ConfirmationDialog from '../../layout/confirmationDialog'

const PrinterStats = (printer, theme) => (
	<Paper
		style={{
			height: '100%',
			width: '100%',
			padding: '10px',
			backgroundColor: theme.palette.secondary.light,
		}}
		elevation={4}
	>
		<Box
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'space-between'}
			sx={{
				height: '100%',
			}}
		>
			<Box display={'flex'} justifyContent={'space-between'}>
				<Box>
					<Typography variant={'overline'}>Id:</Typography>
					<Typography mx={1} variant={'overline'}>
						{printer.id}
					</Typography>
				</Box>
				<Box>
					<Typography variant={'overline'}>Tier:</Typography>
					<Typography
						mx={1}
						variant={'button'}
						color={tierColors[printer.tier]}
						sx={{
							textShadow: '1px 1px 0 black',
						}}
					>
						{tiersEnum[printer.tier]}
					</Typography>
				</Box>
			</Box>
			<Box display={'flex'} justifyContent={'center'}>
				<Typography variant={'button'}>Stamina</Typography>
			</Box>
			<ProgressBar
				width={'100%'}
				completed={+printer.currentStamina}
				maxCompleted={+printer.staminaMax}
				isLabelVisible={false}
				bgColor={'#bc9824'}
			/>
			<Box display={'flex'} justifyContent={'space-between'}>
				<Box>
					<Typography variant={'overline'}>Ink:</Typography>
					<Typography mx={1} variant={'overline'}>
						{printer.inkAvailable}
					</Typography>
				</Box>
				<Box>
					<Typography variant={'overline'}>Mold:</Typography>
					<Typography mx={1} variant={'overline'}>
						{printer.mold}
					</Typography>
				</Box>
			</Box>
		</Box>
	</Paper>
)

const PrinterBox = ({ printer, hideButtons = false }) => {
	const theme = useTheme()
	const [showPrinter, setShowPrinter] = useState(true)
	const [showInfo, setShowInfo] = useState(false)
	const containerRef = useRef(null)

	const [confirmationDialog, setConfirmationDialog] = useState({
		open: false,
		uid: undefined,
		title: '',
		message: '',
	})

	const openConfirmationDialog = (title, message, uid) => {
		setConfirmationDialog({
			open: true,
			uid,
			title,
			message,
		})
	}

	return (
		<Card
			sx={{
				backgroundColor: theme.palette.secondary.light,
				boxShadow: `inset 0 0 100px 50px ${tierColors[printer.tier]}`,
			}}
			variant={'elevation'}
			ref={containerRef}
		>
			<CardContent
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '180px',
					padding: '15px',
				}}
			>
				{showInfo && (
					<Slide
						direction="up"
						in={showInfo}
						container={containerRef.current}
					>
						{PrinterStats(printer, theme)}
					</Slide>
				)}
				{showPrinter && (
					<Slide in={showPrinter} container={containerRef.current}>
						<img
							style={{
								height: '150px',
								width: '150px',
							}}
							src={`/printers/${printer.tier}.png`}
							srcSet={`/printers/${printer.tier}.png 2x`}
						/>
					</Slide>
				)}
			</CardContent>
			{!hideButtons && (
				<CardActions
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Button
						variant={'contained'}
						onClick={() =>
							openConfirmationDialog(
								`Refill ink ${printer.id}`,
								'Please confirm your action'
							)
						}
					>
						<img
							style={{
								height: '25px',
								width: '25px',
							}}
							src={'/icons/ink.svg'}
						/>
					</Button>
					<Button
						variant={'contained'}
						sx={{
							backgroundColor: showInfo
								? theme.palette.primary.dark
								: theme.palette.primary.main,
						}}
						onClick={() => {
							setShowInfo(prev => !prev)
							setShowPrinter(prev => !prev)
						}}
					>
						<img
							style={{
								height: '25px',
								width: '25px',
							}}
							src={
								'https://img.icons8.com/ios-filled/50/000000/info.png'
							}
						/>
					</Button>
					<Button
						variant={'contained'}
						onClick={() =>
							openConfirmationDialog(
								`Refill paper ${printer.id}`,
								'Please confirm your action'
							)
						}
					>
						<img
							style={{
								height: '25px',
								width: '25px',
							}}
							src={'/icons/paper.svg'}
						/>
					</Button>
				</CardActions>
			)}
			<ConfirmationDialog
				data={confirmationDialog}
				handleClose={value => {
					setConfirmationDialog({
						...confirmationDialog,
						open: false,
					})
				}}
			/>
		</Card>
	)
}

export default PrinterBox
