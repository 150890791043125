import { useTheme } from '@emotion/react'
import { Grid } from '@mui/material'
import PrinterBox from 'src/components/printerBox'

const PrinterPanel = ({ printers }) => {
	const theme = useTheme()

	return (
		<Grid
			sx={{
				[theme.breakpoints.down('md')]: {
					display: 'flex',
					justifyContent: 'center',
				},
			}}
			columns={{ xs: 4, sm: 12, md: 12 }}
			container
			spacing={2}
		>
			{printers.map((p, i) => (
				<Grid xs={3} sm={4} md={3} item key={i}>
					<PrinterBox printer={p} />
				</Grid>
			))}
		</Grid>
	)
}

export default PrinterPanel
