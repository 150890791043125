import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'

const NavigationLink = ({ name, path, variant = 'h6' }) => {
	const theme = useTheme()

	return (
		<NavLink
			style={({ isActive }) => {
				return {
					display: 'block',
					margin: '1rem 0',
					textDecoration: 'none',
					color: isActive ? theme.palette.primary.main : 'white',
				}
			}}
			to={`/${path}`}
		>
			<Box sx={{ marginRight: '25px' }}>
				<Typography variant={variant}>{name}</Typography>
			</Box>
		</NavLink>
	)
}

export default NavigationLink
