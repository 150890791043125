export const supportedChain = {
	name: 'BSC Testnet',
	short_name: 'bsc',
	chain: 'smartchain',
	network: 'mainnet',
	chain_id: 97,
	network_id: 97,
	rpc_url: 'https://data-seed-prebsc-1-s1.binance.org:8545',
	native_currency: {
		symbol: 'BNB',
		name: 'BNB',
		decimals: '18',
		contractAddress: '',
		balance: '',
	},
}
