import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'
import NavigationLink from '../../layout/navbar/NavigationLink'

const MintIndexPage = () => {
	return (
		<Box>
			<Box display={'flex'} justifyContent={'center'}>
				<NavigationLink name={'Printer'} path={'mint/printer'} />
				<NavigationLink name={'Printing Room'} path={'mint/room'} />
				<NavigationLink name={'Molds'} path={'mint/molds'} />
			</Box>
			<Outlet />
		</Box>
	)
}

export default MintIndexPage
