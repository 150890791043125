import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingModal from '../../components/loadingModal'
import MintDialog from '../../components/mintDialog'
import MintModal from '../../components/mintModal'
import PrinterBox from '../../components/printerBox'
import {
	setIsError,
	setIsLoading,
	setIsMinting,
	setPrintingRoomSize,
} from '../../features/gameDataSlice'
import printerService from '../../services/printerService'

const MintPrintingRoom = () => {
	const { printingRoomSize, printers } = useSelector(state => state.gameData)
	const [mintedPrinterRoom, setMintedPrinterRoom] = useState(undefined)
	const [dialogOpen, setDialogOpen] = useState(false)
	const dispatch = useDispatch()

	const mintDone = async res => {
		try {
			dispatch(setIsLoading(true))
			const printingRoomSize = await printerService.getPrintingRoomSize()
			dispatch(setPrintingRoomSize(printingRoomSize))
			dispatch(setIsLoading(false))
			setDialogOpen(true)
			setMintedPrinterRoom(res.events.NewPrintingRoom.returnValues.tier)
		} catch (e) {
			dispatch(
				setIsError({
					value: true,
					message: e.message,
				})
			)
		} finally {
			dispatch(setIsLoading(false))
		}
	}

	const mintPrintingRoom = async () => {
		dispatch(setIsMinting(true))
		printerService
			.mintPrintingRoom()
			.then(res => mintDone(res))
			.catch(e => {
				dispatch(
					setIsError({
						message: e.message,
					})
				)
			})
			.finally(() => dispatch(setIsMinting(false)))
	}

	return (
		<Box>
			<MintModal />
			<MintDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<Typography color={'primary'} variant={'overline'}>
					Minted {mintedPrinterRoom} place(s) !
				</Typography>
			</MintDialog>
			<Box display={'flex'} justifyContent={'center'}>
				<Typography color={'primary'} variant={'overline'}>
					You have {printingRoomSize} place(s) and{' '}
					<strong>{printingRoomSize - printers.length}</strong>{' '}
					place(s) available
				</Typography>
			</Box>
			<Box display={'flex'} justifyContent={'center'}>
				<Button variant={'contained'} onClick={mintPrintingRoom}>
					Mint printing room
				</Button>
			</Box>
		</Box>
	)
}

export default MintPrintingRoom
