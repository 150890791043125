export const tiersEnum = {
	5: 'S',
	4: 'A',
	3: 'B',
	2: 'C',
	1: 'D',
	0: 'E',
}

export const tierColors = {
	5: '#d74b4b',
	4: '#ac55db',
	3: '#85e01d',
	2: '#52bdf3',
	1: '#8d652a',
	0: '#4a4a4a',
}
