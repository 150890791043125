import BigNumber from 'big-number'
import web3 from 'web3'
import web3Contract from 'web3-eth-contract'
import {
	ADDRESS_BNB,
	ADDRESS_BUSD,
	ADDRESS_FACTORY,
	ADDRESS_STORAGE,
	ADDRESS_TEST,
	ADDRESS_TOKEN,
} from 'src/config'
import ERC20 from 'src/contracts/ERC20.json'
import factoryAbi from 'src/contracts/factory.json'
import storageAbi from 'src/contracts/storage.json'

class PrinterService {
	constructor() {
		this.isReady = false
	}

	init(web3provider, address) {
		this.address = address
		this.web3provider = web3provider
		this.provider = web3provider.provider
		web3Contract.setProvider(web3provider.provider)
		this.bUsdContract = new web3Contract(ERC20, ADDRESS_BUSD, {
			from: address,
		})
		this.bnbContract = new web3Contract(ERC20, ADDRESS_BNB, {
			from: address,
		})
		this.moneyContract = new web3Contract(ERC20, ADDRESS_TOKEN, {
			from: address,
		})
		this.factoryContract = new web3Contract(factoryAbi, ADDRESS_FACTORY, {
			from: address,
		})
		this.storageContract = new web3Contract(storageAbi, ADDRESS_STORAGE, {
			from: address,
		})
		this.isReady = true
	}

	async getAllowances() {
		const busdAllowance = await this.bUsdContract.methods
			.allowance(this.address, ADDRESS_FACTORY)
			.call()
		const moneyAllowance = await this.moneyContract.methods
			.allowance(this.address, ADDRESS_FACTORY)
			.call()
		return { busd: busdAllowance, money: moneyAllowance }
	}

	async approveBusd(
		amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
	) {
		await this.bUsdContract.methods.approve(ADDRESS_FACTORY, amount).send()
		return await this.getAllowances()
	}

	async approveMoney(
		amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
	) {
		await this.moneyContract.methods.approve(ADDRESS_FACTORY, amount).send()
		return await this.getAllowances()
	}

	async getPrintingRoomSize() {
		return await this.factoryContract.methods.getPrintingRoomSize().call()
	}

	async getPrintersId() {
		return await this.storageContract.methods.getPrintersForAddress().call()
	}

	async getPrinter(id) {
		return await this.storageContract.methods.getPrinter(id).call()
	}

	async getPrinterPrice() {
		return await this.factoryContract.methods.printerMintPrice().call()
	}

	async getPrintingRoomPrice() {
		return await this.factoryContract.methods.printingRoomMintPrice().call()
	}

	async checkAllowance(price) {
		const allowances = await this.getAllowances()

		if (web3.utils.fromWei(allowances.busd, 'ether') < price) {
			await this.approveBusd()
		}
		if (web3.utils.fromWei(allowances.money, 'ether') < price) {
			await this.approveMoney()
		}
	}

	async mintPrinter() {
		await this.checkAllowance(await this.getPrinterPrice())
		return await this.factoryContract.methods.mintPrinter().send()
	}

	async mintPrintingRoom() {
		await this.checkAllowance(await this.getPrintingRoomPrice())
		return await this.factoryContract.methods.mintPrintingRoom().send()
	}

	async getMoneyBalance() {
		return await this.moneyContract.methods.balanceOf(this.address).call()
	}

	async getBusdBalance() {
		return await this.bUsdContract.methods.balanceOf(this.address).call()
	}

	async getBnbBalance() {
		return 0
		// return await this.bnbContract.methods.balanceOf(this.address).call()
	}
}

const printerService = new PrinterService()

export default printerService
