import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	printers: [],
	molds: [],
	printingRoomSize: 0,
	isLoading: false,
	isMinting: false,
	isError: undefined,
}

export const gameDataSlice = createSlice({
	name: 'gameData',
	initialState,
	reducers: {
		setPrinters: (state, action) => {
			state.printers = action.payload
		},
		setPrintingRoomSize: (state, action) => {
			state.printingRoomSize = action.payload
		},
		setMolds: (state, action) => {
			state.molds = action.payload
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload
		},
		setIsMinting: (state, action) => {
			state.isMinting = action.payload
		},
		setIsError: (state, action) => {
			state.isError = action.payload
		},
		resetGameData: (state, action) => {
			return initialState
		},
	},
})

export const {
	setIsLoading,
	setIsMinting,
	resetGameData,
	setIsError,
	setMolds,
	setPrinters,
	setPrintingRoomSize,
} = gameDataSlice.actions

export default gameDataSlice.reducer
