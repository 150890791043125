import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	provider: undefined,
	web3Provider: undefined,
	address: undefined,
	chainId: undefined,
	balance: {
		busd: 0,
		money: 0,
		bnb: 0,
	},
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setWeb3Provider: (state, action) => {
			return {
				...state,
				provider: action.payload.provider,
				web3Provider: action.payload.web3Provider,
				address: action.payload.address,
				chainId: action.payload.chainId,
			}
		},
		setAddress: (state, action) => {
			state.address = action.payload
		},
		setBusd: (state, action) => {
			state.balance.busd = action.payload
		},
		setMoney: (state, action) => {
			state.balance.money = action.payload
		},
		setBnb: (state, action) => {
			state.balance.bnb = action.payload
		},
		setBalance: (state, action) => {
			state.balance = action.payload
		},
		setChainId: (state, action) => {
			state.chainId = action.payload
		},
		resetWeb3Provider: (state, action) => {
			return initialState
		},
	},
})

export const {
	setWeb3Provider,
	setAddress,
	setChainId,
	resetWeb3Provider,
	setBalance,
	setBnb,
	setBusd,
	setMoney,
} = userSlice.actions

export default userSlice.reducer
