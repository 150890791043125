import { useTheme } from '@emotion/react'
import { LinearProgress } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import printerService from '../../services/printerService'

const style = theme => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	[theme.breakpoints.down('sm')]: {
		width: '95vw',
	},
})

const MintModal = () => {
	const { isMinting } = useSelector(state => state.gameData)
	const [isOpen, setIsOpen] = useState(false)
	const theme = useTheme()

	useEffect(() => {
		setIsOpen(isMinting)
	}, [isMinting])

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={isOpen}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={isOpen}>
				<Box sx={style(theme)}>
					<Typography variant="h6">
						Please wait while you're minting...
					</Typography>
					<LinearProgress sx={{ marginTop: '20px' }} />
				</Box>
			</Fade>
		</Modal>
	)
}

export default MintModal
