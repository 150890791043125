import { ThemeProvider } from '@emotion/react'
import { Box, CssBaseline, Typography } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Routes } from 'react-router-dom'
import App from './App'
import Home from './pages/Home'
import MarketplacePage from './pages/Marketplace'
import NotFoundPage from './pages/NotFound'
import PrintPage from './pages/Print'
import CollectionPage from './pages/collections'
import MintIndexPage from './pages/mint'
import MintPrinter from './pages/mint/MintPrinter'
import MintPrintingRoom from './pages/mint/MintPrintingRoom'
import { store } from './store'
import theme from './theme'

const ProtectedRoute = ({ render }) => {
	const { address } = useSelector(state => state.user)

	if (address === undefined) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Typography color={'primary'}>
					You first need to connect your wallet
				</Typography>
			</Box>
		)
	}
	return render
}

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<App />}>
						<Route path="/" element={<Home />} />
						<Route path="/print" element={<PrintPage />} />
						<Route
							path="/marketplace"
							element={
								<ProtectedRoute render={<MarketplacePage />} />
							}
						/>
						<Route
							path="/collections"
							element={
								<ProtectedRoute render={<CollectionPage />} />
							}
						/>
						<Route
							path="/mint"
							element={
								<ProtectedRoute render={<MintIndexPage />} />
							}
						>
							<Route
								path="/mint/printer"
								element={
									<ProtectedRoute render={<MintPrinter />} />
								}
							/>
							<Route
								path="/mint/room"
								element={
									<ProtectedRoute
										render={<MintPrintingRoom />}
									/>
								}
							/>
						</Route>
						<Route path="*" element={<NotFoundPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	</Provider>,
	document.getElementById('root')
)
