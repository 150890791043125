import { Box, Typography } from '@mui/material'

const PrintPage = () => {
	return (
		<Box>
			<Typography>Print page</Typography>
		</Box>
	)
}

export default PrintPage
