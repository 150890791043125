import { useTheme } from '@emotion/react'
import {
	AttachMoney,
	CollectionsBookmark,
	Favorite,
	Storefront,
	VideogameAsset,
} from '@mui/icons-material'
import {
	BottomNavigation,
	BottomNavigationAction,
	Box,
	Paper,
	useMediaQuery,
} from '@mui/material'
import BigNumber from 'big-number'
import web3 from 'web3'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import Navbar from 'src/layout/navbar/Navbar'
import ErrorModal from './components/errorModal'
import LoadingModal from './components/loadingModal'
import { ADDRESS_BNB } from './config'
import {
	setIsError,
	setIsLoading,
	setPrinters,
	setPrintingRoomSize,
} from './features/gameDataSlice'
import { setBalance } from './features/userSlice'
import printerService from './services/printerService'

function App() {
	const { address, web3Provider } = useSelector(state => state.user)
	const dispatch = useDispatch()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const navigate = useNavigate()

	const [bottomNavIndex, setBottomNavIndex] = useState(0)

	const fetchData = async () => {
		try {
			dispatch(setIsLoading(true))
			const printingRoomSize = await printerService.getPrintingRoomSize()
			dispatch(setPrintingRoomSize(printingRoomSize))
			const printersId = await printerService.getPrintersId()
			const printers = []
			for (let id in printersId) {
				const printer = await printerService.getPrinter(id)
				printers.push(printer)
			}
			dispatch(setPrinters(printers))
			const moneyBalance = web3.utils.fromWei(
				await printerService.getMoneyBalance(),
				'ether'
			)
			const busdBalance = web3.utils.fromWei(
				await printerService.getMoneyBalance(),
				'ether'
			)
			const bnbBalance = 0
			dispatch(
				setBalance({
					money: moneyBalance,
					busd: busdBalance,
					bnb: bnbBalance,
				})
			)
		} catch (e) {
			dispatch(
				setIsError({
					value: true,
					message: e.message,
				})
			)
		} finally {
			dispatch(setIsLoading(false))
		}
	}

	useEffect(() => {
		if (
			web3Provider !== undefined &&
			address !== undefined &&
			!printerService.isReady
		) {
			printerService.init(web3Provider, address)
			fetchData()
		}
	}, [address, web3Provider])

	return (
		<Box
			sx={{
				paddingBottom: isMobile ? '50px' : '0px',
			}}
		>
			<ErrorModal />
			<LoadingModal />
			<Navbar />
			<Outlet />
			{isMobile && (
				<Paper
					sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
					elevation={0}
				>
					<BottomNavigation
						sx={{
							backgroundColor: theme =>
								theme.palette.secondary.dark,
						}}
						showLabels
						value={bottomNavIndex}
						onChange={(event, newValue) => {
							setBottomNavIndex(newValue)
							switch (newValue) {
								case 0:
									navigate('/collections')
									break
								case 1:
									navigate('/play')
									break
								case 2:
									navigate('/mint')
									break
								case 3:
									navigate('/marketplace')
									break
							}
						}}
					>
						<BottomNavigationAction
							label="Collections"
							icon={<CollectionsBookmark />}
						/>
						<BottomNavigationAction
							label="Play"
							icon={<VideogameAsset />}
						/>
						<BottomNavigationAction
							label="Mint"
							icon={<AttachMoney />}
						/>
						<BottomNavigationAction
							label="Marketplace"
							icon={<Storefront />}
						/>
					</BottomNavigation>
				</Paper>
			)}
		</Box>
	)
}

export default App
