import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import { supportedChain } from './chains'

const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider,
		options: {
			infuraId: '5cf857aa092d6d934ab87e2ea8da872a',
		},
	},
}

export const web3Modal = new Web3Modal({
	providerOptions,
	cacheProvider: true,
})

export function getChainData(chainId) {
	if (!chainId) {
		return null
	}

	const chainData =
		supportedChain.chain_id === chainId ? supportedChain : undefined

	if (!chainData) {
		throw new Error(
			'Chain not supported, please go to ' + supportedChain.name
		)
	}

	const API_KEY = '460f40a260564ac4a4f4b3fffb032dad'

	if (
		chainData.rpc_url.includes('infura.io') &&
		chainData.rpc_url.includes('%API_KEY%') &&
		API_KEY
	) {
		const rpcUrl = chainData.rpc_url.replace('%API_KEY%', API_KEY)

		return {
			...chainData,
			rpc_url: rpcUrl,
		}
	}

	return chainData
}

export function ellipseAddress(address = '', width = 10) {
	if (!address) {
		return ''
	}
	return `${address.slice(0, width)}...${address.slice(-width)}`
}

export class web3Disconnect {}
