import { useTheme } from '@emotion/react'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import TabPanel from 'src/layout/tabPanel'
import LoadingModal from '../../components/loadingModal'
import PrinterPanel from './printerPanel'

const CollectionPage = () => {
	const [tabIndex, setTabIndex] = useState(0)
	const { printers, printingRoomSize } = useSelector(state => state.gameData)
	const theme = useTheme()

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue)
	}

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<Box
				sx={{
					display: 'flex',
					width: '90%',
					flexDirection: 'column',
					[theme.breakpoints.down('md')]: {
						width: '100%',
					},
				}}
			>
				<Tabs value={tabIndex} onChange={handleTabChange} centered>
					<Tab label="Printers" />
					<Tab label="Molds" />
				</Tabs>
				<TabPanel value={tabIndex} index={0}>
					<Box
						sx={{ marginBottom: '10px' }}
						display={'flex'}
						justifyContent={'center'}
					>
						<Typography variant={'h6'} color={'primary'}>
							Printing room size: {printingRoomSize}
						</Typography>
					</Box>
					<PrinterPanel printers={printers} />
				</TabPanel>
			</Box>
		</Box>
	)
}

export default CollectionPage
