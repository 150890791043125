import { useTheme } from '@emotion/react'
import { Box, Button, Slide, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingModal from '../../components/loadingModal'
import MintDialog from '../../components/mintDialog'
import MintModal from '../../components/mintModal'
import PrinterBox from '../../components/printerBox'
import {
	setIsLoading,
	setIsMinting,
	setPrinters,
} from '../../features/gameDataSlice'
import printerService from '../../services/printerService'
import theme from '../../theme'

function between(min, max) {
	return Math.floor(Math.random() * (max - min) + min)
}

const MintPrinter = () => {
	const { printers, printingRoomSize } = useSelector(state => state.gameData)
	const [randomTier, setRandomTier] = useState(0)
	const [mintedPrinter, setMintedPrinter] = useState(undefined)
	const [dialogOpen, setDialogOpen] = useState(false)
	const dispatch = useDispatch()
	const theme = useTheme()

	useEffect(() => {
		const timer = setInterval(() => {
			setRandomTier(between(0, 5))
		}, 2000)
		return () => clearInterval(timer)
	}, [])

	const mintDone = async res => {
		dispatch(setIsLoading(true))
		const printersId = await printerService.getPrintersId()
		const printers = []
		for (let id in printersId) {
			const printer = await printerService.getPrinter(id)
			printers.push(printer)
		}
		dispatch(setPrinters(printers))
		setMintedPrinter(
			await printerService.getPrinter(
				res.events.NewPrinter.returnValues.printerId
			)
		)
		dispatch(setIsLoading(false))
		setDialogOpen(true)
	}

	const mintPrinter = async () => {
		dispatch(setIsMinting(true))
		printerService
			.mintPrinter()
			.then(res => mintDone(res))
			.finally(() => dispatch(setIsMinting(false)))
	}

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<MintDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<PrinterBox printer={mintedPrinter} hideButtons />
			</MintDialog>
			<MintModal />
			<Box
				sx={{
					display: 'flex',
					padding: '20px',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: 'column',
					borderRadius: '5px',
					backgroundColor: theme.palette.secondary.light,
					[theme.breakpoints.down('sm')]: {
						width: '95%',
					},
				}}
			>
				<PrinterBox printer={{ tier: randomTier }} hideButtons />
				<Box display={'flex'} justifyContent={'center'}>
					<Typography color={'primary'} variant={'overline'}>
						You can mint{' '}
						<strong>{printingRoomSize - printers.length}</strong>{' '}
						printer(s)
					</Typography>
				</Box>
				<Box display={'flex'} justifyContent={'center'}>
					<Button
						variant={'contained'}
						onClick={mintPrinter}
						disabled={!(+printingRoomSize - printers.length > 0)}
					>
						{+printingRoomSize - printers.length > 0
							? 'Mint printer'
							: 'Not enough space in printing room'}
					</Button>
				</Box>
			</Box>
		</Box>
	)
}

export default MintPrinter
