import { configureStore } from '@reduxjs/toolkit'
import gameDataReducer from './features/gameDataSlice'
import userReducer from './features/userSlice'

export const store = configureStore({
	reducer: {
		user: userReducer,
		gameData: gameDataReducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActionPaths: [
					'payload.provider',
					'payload.web3Provider',
					'payload.isError',
					'gameData/setIsError',
				],
				ignoredPaths: [
					'user.provider',
					'user.web3Provider',
					'gameData.setIsError',
					'gameData/setIsError',
					'gameData.isError',
				],
			},
		}),
})
