import styled from '@emotion/styled'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
} from '@mui/material'
import * as React from 'react'

const BootstrapDialog = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.main,
}))

const MintDialog = ({ open = false, onClose, children }) => {
	return (
		<div>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperComponent={BootstrapDialog}
			>
				<DialogTitle color={'primary'} id="alert-dialog-title">
					{'Mint done !'}
				</DialogTitle>
				<DialogContent>{children}</DialogContent>
				<DialogActions>
					<Button onClick={onClose} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default MintDialog
